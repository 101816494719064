// LoadingIndicator.jsx
import React from 'react'

function LoadingIndicator() {
  const sprocketColor = '#EA8B6A' // Define the exact color

  return (
    <div
      style={{
        position: 'fixed', // Ensure it covers the entire screen
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(145, 195, 222, 0.8)', // Slightly transparent #91C3DE
        zIndex: 1000, // High z-index to cover all elements
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: "'Jost', sans-serif" // Font set to Jost
      }}>
      {/* Main sprocket */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
        <img
          src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/sprocket.svg?v=1717219866"
          alt="Loading"
          style={{
            width: '150px', // Three times original size
            height: '150px',
            animation: 'spin 2s linear infinite',
            filter: `invert(53%) sepia(78%) saturate(3000%) hue-rotate(0deg) brightness(93%) contrast(90%)`
          }}
        />
      </div>

      {/* Text below the main sprocket */}
      <p
        style={{
          position: 'absolute',
          top: 'calc(50% + 80px)',
          left: '50%',
          transform: 'translateX(-50%)',
          color: '#FFFFFF', // White text color
          fontSize: '24px',
          fontFamily: "'Jost', sans-serif"
        }}>
        Loading...
      </p>

      {/* Additional sprockets */}
      {[1, 2, 3].map((_, index) => (
        <img
          key={index}
          src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/sprocket.svg?v=1717219866"
          alt="Loading"
          style={{
            position: 'absolute',
            top: `${10 + index * 5}%`, // Scatter vertically
            left: `${10 + index * 25}%`, // Scatter horizontally
            width: `${50 + index * 20}px`, // Varying sizes
            height: `${50 + index * 20}px`,
            animation: `spin 2s linear infinite ${index * 0.5}s`, // Delay to offset spin
            filter: 'invert(53%) sepia(78%) saturate(3000%) hue-rotate(0deg) brightness(93%) contrast(90%)'
          }}
        />
      ))}

      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  )
}

export default LoadingIndicator
