import * as THREE from 'three'
import { useRef, useLayoutEffect, useState, useMemo } from 'react'
import { Canvas, useThree, useLoader } from '@react-three/fiber'
import { Center, AccumulativeShadows, RandomizedLight, OrbitControls, Environment, useGLTF } from '@react-three/drei'
import React, { useEffect, Component } from 'react'
import AWS from 'aws-sdk'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js'
import './styles.css' // Make sure to link your CSS file
import { useTexture } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { TextureLoader } from 'three'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import TWEEN from '@tweenjs/tween.js'
import { useFrame } from '@react-three/fiber'
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'
import ReactDOM from 'react-dom'
import AppRouter from './AppRouter'
import { useID } from './IDContext' // Adjust the path as necessary
import { IDProvider } from './IDContext'
import createApp from '@shopify/app-bridge'
import { Redirect } from '@shopify/app-bridge/actions'
import { createRoot } from 'react-dom/client'
const container = document.getElementById('root')
const root = createRoot(container)

import { Raycaster, Vector2 } from 'three'
import { motion, useAnimation, useMotionValue, useTransform, animate } from 'framer-motion'

// Colors and SKU code to update options
import { colorOptions, screenColorOptions, fabricColorOptions, options } from './colors'

// Code for the loading
import LoadingIndicator from './LoadingIndicator'

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
)

// Start OAuth flow by calling the Netlify function
const startAuth = async (shop) => {
  window.location.href = `/.netlify/functions/auth?shop=${shop}`
}

function AnimationUpdater() {
  useFrame(() => TWEEN.update())
  return null // This component does not render anything
}

function setEnvironmentMap(scene) {
  const loader = new RGBELoader()
  loader.load('https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/abandoned_greenhouse_1k.hdr', (texture) => {
    texture.mapping = THREE.EquirectangularReflectionMapping
    scene.environment = texture
  })
}

function GroundPlane() {
  const texture = useLoader(TextureLoader, 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/240415_Shadowbaked.png?v=1713229807')
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(1, 1)
  texture.anisotropy = 16 // Enhance texture quality

  // Darken the texture by applying a darker color multiplier
  const material = new THREE.MeshStandardMaterial({
    map: texture,
    transparent: true
  })

  return (
    <mesh rotation={[-Math.PI / 2, 0, -0.02]} position={[-0.88, -0.3, 1.1]} scale={[0.4, 0.4, 0.4]}>
      <planeGeometry args={[500, 500]} />
      <primitive object={material} attach="material" />
    </mesh>
  )
}

function LightMarker({ position }) {
  const mesh = useRef()
  const lightMaterial = useMemo(() => new THREE.MeshBasicMaterial({ color: 0xffff00 }), [])
  return (
    <mesh ref={mesh} position={position} material={lightMaterial}>
      <sphereGeometry args={[0.1, 16, 16]} />
    </mesh>
  )
}

function Suzi({ color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType, activeSelection, isThumbnail, ...props }) {
  const { scene } = useGLTF('https://cdn.shopify.com/3d/models/bd60ece5eec8c1bc/241101_customizer_smaller.glb')

  useLayoutEffect(() => {
    scene.traverse((obj) => {
      if (obj.isMesh) {
      }

      if (obj.isMesh) {
        obj.receiveShadow = obj.castShadow = true
        obj.visible = false // Initially hide all meshes.

        if (obj.name === 'body') {
          obj.material.color.set(color)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.7
          obj.visible = true
        }
        if (obj.name === 'saati_top') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = true // Make material transparent
          let opacity = fabricColor === '#FFFFFF' ? 0.55 : 0.55 // Set opacity based on fabricColor, though this conditional seems unnecessary
          obj.material.opacity = isThumbnail ? 1 : opacity // Adjust opacity for thumbnail
          obj.visible = true
        }
        if (obj.name === 'saati_bottom') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = false // Make material opaque
          obj.visible = true
        }

        if (obj.name === 'saati_bottomback') {
          obj.material.color.set(fabricColor)
          obj.material.transparent = false // Make material opaque
          obj.visible = true
        }
        const isScreenTopVisible = obj.name === `${screenType}_top`
        const isScreenBottomVisibleBack = obj.name === `${screenType}_bottomback`

        if (isScreenTopVisible || isScreenBottomVisibleBack) {
          obj.material.color.set(screenColor)
          obj.material.needsUpdate = true
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.7
          obj.visible = true
        }

        const isBottomScreenVisible = bottomScreenType === 'Add Pattern Screen' && obj.name === `${screenType}_bottom`
        if (isBottomScreenVisible) {
          obj.material.color.set(screenColor)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.2
          obj.visible = true
        }

        if ((bottomScreenType === 'Logo' && obj.name === 'logo') || (bottomScreenType === 'Custom Logo' && obj.name === 'custom_logo')) {
          obj.material.color.set(screenColor)
          obj.material.roughness = 0.5 // Adds some slight roughness
          obj.material.metalness = 0.2
          obj.visible = true
        }

        if ((microphoneType === 'Condenser' && obj.name.includes('capsule')) || (microphoneType === 'Ribbon' && obj.name.includes('ribbon'))) {
          obj.visible = true
        }
      }
    })
  }, [color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType, isThumbnail])

  useEffect(() => {
    scene.traverse((obj) => {
      if (obj.isMesh) {
        // Ensure 'body' and 'saati_bottom' are always visible
        if (obj.name === 'body' || obj.name === 'saati_bottom') {
          obj.visible = true
        }

        // Fade-out or fade-in for top screens only when activeSelection changes
        if (obj.name === `${screenType}_top` || obj.name === 'saati_top') {
          const targetOpacity = activeSelection === '6. Microphone Type' ? 0 : obj.name === 'saati_top' ? (isThumbnail ? 1 : 0.55) : isThumbnail ? 1 : 1

          // Clone the material if not already cloned
          if (!obj.material._isCloned) {
            obj.material = obj.material.clone()
            obj.material._isCloned = true
          }

          // Only apply opacity change when activeSelection changes
          if (activeSelection !== '6. Microphone Type' || targetOpacity === 0) {
            obj.material.transparent = true

            // Only make visible if targetOpacity > 0 to prevent unintended reappearance
            if (targetOpacity > 0) {
              obj.visible = true
            }

            new TWEEN.Tween(obj.material)
              .to({ opacity: targetOpacity }, 500)
              .easing(TWEEN.Easing.Quadratic.Out)
              .onUpdate(() => {
                obj.material.needsUpdate = true
              })
              .onComplete(() => {
                // Hide the object only if opacity is fully faded out
                if (targetOpacity === 0) {
                  obj.visible = false
                }
              })
              .start()
          }
        }

        // Control visibility of 'capsule' and 'ribbon' based on microphoneType only, without affecting top screen
        if ((microphoneType === 'Condenser' && obj.name === 'capsule') || (microphoneType === 'Ribbon' && obj.name === 'ribbon')) {
          obj.visible = true
        } else if (obj.name === 'capsule' || obj.name === 'ribbon') {
          obj.visible = false
        }
      }
    })
  }, [activeSelection, microphoneType, screenType, screenColor, scene, isThumbnail])

  useFrame(() => {
    TWEEN.update() // necessary for tween animations to update on each frame
  })

  return <primitive object={scene} {...props} />
}

function debounce(func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

function createThumbnailCamera(originalCamera) {
  const aspect = 1 // Square aspect ratio
  const thumbnailCamera = originalCamera.clone()
  thumbnailCamera.aspect = aspect
  thumbnailCamera.updateProjectionMatrix()
  return thumbnailCamera
}

// Outside the function (module scope)
let lastDotPressTimestamp = 0

function setActiveDot(dotClassName, cameraFunction) {
  const now = Date.now()

  // Only allow a new press if at least 4 seconds (4000ms) have passed.
  if (now - lastDotPressTimestamp < 2000) {
    return
  }

  // Update the last pressed timestamp
  lastDotPressTimestamp = now

  // Remove "active" class from all dots
  document.querySelectorAll('.control-dots div').forEach((dot) => dot.classList.remove('active'))

  // Add "active" class to the selected dot
  const targetDot = document.querySelector(`.${dotClassName}`)
  if (targetDot) {
    targetDot.classList.add('active')
  } else {
  }

  // Call the camera function (if provided)
  if (cameraFunction) {
    cameraFunction()
  }
}

const urlParams = new URLSearchParams(window.location.search)
const code = urlParams.get('code')
const host = urlParams.get('host') // 'host' should be present in your app URL

const response = await fetch(`https://ohma-world.myshopify.com/admin/oauth/access_token`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    client_id: process.env.SHOPIFY_API_KEY, // Client ID from Shopify Partners
    client_secret: process.env.SHOPIFY_API_SECRET, // Client Secret from Shopify Partners
    code // Code from the authorization redirect
  })
})

const data = await response.json()
const accessToken = data.access_token

if (host) {
  const app = createApp({
    apiKey: process.env.SHOPIFY_API_KEY,
    host
  })

  // Example: Redirecting to another page in the Shopify Admin using App Bridge
  const redirect = Redirect.create(app)
  redirect.dispatch(Redirect.Action.ADMIN_PATH, '/settings')
} else {
}

function useMediaQuery(query) {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const media = window.matchMedia(query)
    setMatches(media.matches)

    const listener = () => setMatches(media.matches)
    media.addEventListener('change', listener)

    return () => media.removeEventListener('change', listener)
  }, [query])

  return matches
}

export default function App() {
  const sceneRef = useRef()
  const cameraRef = useRef()
  const orbitControlsRef = useRef()

  const [color, setColor] = useState('#4fb9af')
  const [screenType, setScreenType] = useState('motif')
  const [screenColor, setScreenColor] = useState('#FFFFFF')
  const [fabricColor, setFabricColor] = useState('#FFFFFF')
  const [bottomScreenType, setBottomScreenType] = useState('Logo')
  const [microphoneType, setMicrophoneType] = useState('Condenser')
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [fetchedData, setFetchedData] = useState(null)

  const screenTypeOptions = {
    motif: { label: 'Motif', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Motif.png?v=1737070108' },
    stripes: { label: 'Stripes', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Stripes.png?v=1737070109' },
    windows: { label: 'Windows', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Windows.png?v=1737070109' },
    scales: { label: 'Scales', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Scales.png?v=1737070108' },
    holes: { label: 'Holes', iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Holes.png?v=1737070108' }
    // Add other screen types as necessary
  }

  const bottomScreenTypeOptions = {
    Logo: {
      label: 'Ohma Logo',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Bottom_Logo_Logo.png?v=1737074772' // Placeholder URL
    },
    'Add Pattern Screen': {
      label: `${screenTypeOptions[screenType].label} Bottom Screen`,
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Customizer_Icons_Motif.png?v=1737070108' // Placeholder URL
    },
    'Custom Logo': {
      label: 'Custom Logo',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Bottom_Logo_Design_Your_Own_5f7023ff-6a8a-4730-97cd-84ec5741493f.png?v=1737076115' // Placeholder URL
    }
  }

  const microphoneTypeOptions = {
    Condenser: {
      label: 'Condenser',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Condenser_Icon_8e0c5abf-6c55-493d-8f25-64dc1fe3d6e3.png?v=1737070883'
    },
    Ribbon: {
      label: 'Ribbon',
      iconUrl: 'https://cdn.shopify.com/s/files/1/0564/1820/5796/files/250116_Transducer_Ribbon_Icon_7d6282c2-3f3d-4403-a894-cb35aeaa60ee.png?v=1737070883'
    }
    // Add other microphone types as necessary
  }

  // Determine phone type based on viewport dimensions.
  function getPhoneType() {
    const width = window.innerWidth
    const height = window.innerHeight

    // These values are approximate; adjust as needed.
    if (width >= 420 && height >= 730) {
      return 'promax' // Pro Max or similar
    } else if (width >= 380 && height >= 630) {
      return 'regular' // Regular iPhones
    } else if (width >= 370 && height >= 620) {
      return 'se' // SE or similar
    } else {
      return 'unknown' // Fallback
    }
  }

  function getCameraDistance(footerValue) {
    let minDist, midDist, maxDist

    // Choose different distance values depending on phone type.
    if (phoneType === 'se') {
      // For iPhone SE (example values)
      maxDist = 1.18 // at footer 13%
      midDist = 1.56 // at footer 35%
      minDist = 3.0 // at footer 90%
    } else if (phoneType === 'regular') {
      // For Regular iPhones (example values)
      maxDist = 1.05 // at footer 13%
      midDist = 1.5 // at footer 35%
      minDist = 3.0 // at footer 90%
    } else if (phoneType === 'promax') {
      // For Pro Max (or similar) (example values)
      maxDist = 1 // at footer 13%
      midDist = 1.9 // at footer 35%
      minDist = 3.0 // at footer 90%
    } else {
      // Fallback values if phone type is unknown
      maxDist = 1 // at footer 13%
      midDist = 1.45 // at footer 35%
      minDist = 3.0 // at footer 90%
    }

    if (footerValue <= 13) {
      return minDist
    } else if (footerValue <= 35) {
      const ratio = (footerValue - 13) / (35 - 13)
      return minDist + ratio * (midDist - minDist)
    } else if (footerValue <= 90) {
      const ratio = (footerValue - 35) / (90 - 35)
      return midDist + ratio * (maxDist - midDist)
    } else {
      return maxDist
    }
  }

  function updateCameraDistanceInRealTime(footerValue) {
    if (!cameraRef.current) return
    const newDistance = getCameraDistance(footerValue)
    // Get the current forward direction of the camera.
    const direction = new THREE.Vector3()
    cameraRef.current.getWorldDirection(direction)

    // Set the new camera position along the same direction.
    cameraRef.current.position.copy(direction.multiplyScalar(-newDistance))
    cameraRef.current.updateProjectionMatrix()
  }

  // --- Define helper functions for computing default groupY and range based on viewport size ---

  const [phoneType, setPhoneType] = useState(getPhoneType())
  const [viewportDims, setViewportDims] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setPhoneType(getPhoneType())
      setViewportDims({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Compute the default groupY offset for the current device.
  function computeDefaultGroupY() {
    const phoneType = getPhoneType()

    // Adjust these values as needed based on the phone type.
    if (phoneType === 'promax') {
      return 0.165
    } else if (phoneType === 'regular') {
      return 0.015
    } else if (phoneType === 'se') {
      return 0.045 // For example, if you prefer no offset for SE.
    } else {
      return 0.015
    }
  }

  // Return the Y range (min, default, max) for the current device.
  function getGroupYRange() {
    const phoneType = getPhoneType()

    // Customize these range values based on your requirements.
    if (phoneType === 'promax') {
      return { yMax: -0.15, defaultY: 0.165, yMin: -0.01 }
    } else if (phoneType === 'regular') {
      return { yMax: -0.13, defaultY: 0.015, yMin: 0.015 }
    } else if (phoneType === 'se') {
      return { yMax: -0.09, defaultY: 0.045, yMin: 0.01 }
    } else {
      return { yMax: -0.13, defaultY: 0.015, yMin: 0.015 }
    }
  }

  // Example usage:
  const defaultGroupY = computeDefaultGroupY()

  const groupYRange = getGroupYRange()

  // --- In your component ---

  // 1. Use a state variable for groupY initialized from computeDefaultGroupY.
  const [groupY, setGroupY] = useState(computeDefaultGroupY())

  // 2. (Optional) Recompute on resize so that if the screen size changes your offsets update.
  useEffect(() => {
    const handleResize = () => {
      setGroupY(computeDefaultGroupY())
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // 3. Update the model’s vertical position based on the footer value.
  //    Assume the footer value ranges from, for example, 13% (min) to 90% (max)
  //    with a default at 35%. Adjust these numbers as needed.
  function updateModelPositionInRealTime(footerValue) {
    const { yMin, defaultY, yMax } = getGroupYRange()
    if (footerValue <= 13) {
      setGroupY(yMin)
    } else if (footerValue <= 35) {
      // Interpolate between yMin and defaultY
      const ratio = (footerValue - 13) / (35 - 13)
      setGroupY(yMin + ratio * (defaultY - yMin))
    } else if (footerValue <= 90) {
      // Interpolate between defaultY and yMax
      const ratio = (footerValue - 35) / (90 - 35)
      setGroupY(defaultY + ratio * (yMax - defaultY))
    } else {
      setGroupY(yMax)
    }
  }

  useEffect(() => {
    // A function that gets the range and logs the phone type.
    const logPhoneType = () => {
      // Call getGroupYRange (this will log inside its body).
      const groupYRange = getGroupYRange()
      // You could also log the range if needed:
    }

    // Log when the component mounts.
    logPhoneType()

    // Set up a resize listener.
    window.addEventListener('resize', logPhoneType)

    // Clean up the resize listener on unmount.
    return () => window.removeEventListener('resize', logPhoneType)
  }, [])

  // Create a state variable to force a re-render
  const [updateCounter, setUpdateCounter] = useState(0)

  // 2. Compute the initial footer height based on the phone type.
  function computeInitialFooterHeight() {
    const phoneType = getPhoneType()

    if (phoneType === 'se') {
      return 68 // Use 32% for iPhone SE
    } else if (phoneType === 'regular') {
      return 67 // Use 33% for regular devices
    } else if (phoneType === 'promax') {
      return 65 // Use 35% for Pro Max
    } else {
      return 65 // Fallback value
    }
  }

  const snapPositions =
    phoneType === 'se'
      ? [13, 68, 90] // For SE: min = 13%, mid = 32%, max = 90%
      : phoneType === 'regular'
      ? [13, 67, 90] // For regular: min = 13%, mid = 33%, max = 90%
      : [13, 65, 90] // For Pro Max (and others): min = 13%, mid = 35%, max = 90%

  const snapPercents =
    phoneType === 'se'
      ? [13, 68, 90] // For SE: min = 13%, mid = 32%, max = 90%
      : phoneType === 'regular'
      ? [13, 67, 90] // For regular: min = 13%, mid = 33%, max = 90%
      : [13, 65, 90] // For Pro Max (and others): min = 13%, mid = 35%, max = 90%

  const initialFooterValue = snapPercents[1]
  const initialDistance = getCameraDistance(initialFooterValue)

  const initialFooterHeight = computeInitialFooterHeight()
  const [currentFooterHeight, setCurrentFooterHeight] = useState(initialFooterHeight)

  const snapHeights = [100, 300, 600]
  const screenHeight = typeof window !== 'undefined' ? window.innerHeight : 800
  const maxOffset = Math.max(0, screenHeight - 50) // never negative

  const sheetOffset = useMotionValue(snapHeights[1])

  const [liveFooterHeight, setLiveFooterHeight] = useState(snapPositions[1])
  const [isSnapping, setIsSnapping] = useState(false)
  const animateControls = useAnimation()
  const sheetPercent = useMotionValue(snapPercents[1])
  const footerHeight = useMotionValue(snapHeights[0])
  const controls = useAnimation()
  const [liveHeight, setLiveHeight] = useState(snapHeights[0])

  const [liveOffset, setLiveOffset] = useState(snapHeights[1])
  const translateY = useTransform(sheetPercent, (val) => {
    const px = (val / 100) * screenHeight
    return `translateY(${px}px)`
  })

  // 3) We'll store it in local state to conditionally render content (optional)
  const [livePercent, setLivePercent] = useState(snapPercents[1])

  useEffect(() => {
    // Whenever sheetPercent changes, update livePercent
    const unsub = sheetPercent.onChange((val) => {
      updateCameraDistanceInRealTime(val)
      updateModelPositionInRealTime(val)
      setLivePercent(val)
    })
    return () => unsub()
  }, [sheetPercent])

  function nearestSnap(currentPct, snapPercents) {
    let closest = snapPercents[0]
    let minDist = Infinity
    for (let s of snapPercents) {
      const dist = Math.abs(currentPct - s)
      if (dist < minDist) {
        minDist = dist
        closest = s
      }
    }
    return closest
  }

  function handleDragEnd() {
    const currentVal = sheetPercent.get() // e.g. 28
    const snap = nearestSnap(currentVal, snapPercents) // pick 35 if that's closer
    animate(sheetPercent, snap, {
      type: 'spring',
      stiffness: 300,
      damping: 30
    })
  }

  let touchStartTime = 0 // Variable to track the touch start time try
  let activeTempMaterials = [] // Array to track all temporary materials
  let activeBlinkTimeout = null // Timeout to control the active blink group

  const handlePointerDown = () => {
    touchStartTime = Date.now() // Record the time when the touch starts
  }

  const handleMeshTouch = (event) => {
    // Calculate touch duration
    const touchDuration = Date.now() - touchStartTime
    const threshold = 300 // Threshold for a quick tap (in milliseconds)

    if (touchDuration > threshold) {
      return // Ignore the activation for long holds
    }

    if (!sceneRef.current || !cameraRef.current) {
      return
    }

    // Calculate mouse position
    const rect = canvasRef.current.getBoundingClientRect()
    const mouse = new THREE.Vector2(((event.clientX - rect.left) / rect.width) * 2 - 1, -((event.clientY - rect.top) / rect.height) * 2 + 1)

    // Perform raycasting
    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(mouse, cameraRef.current)
    const intersects = raycaster.intersectObjects(sceneRef.current.children, true)

    if (intersects.length > 0) {
      const selectedMesh = intersects[0].object

      // Define an array to collect meshes for the group
      const meshesToBlink = [selectedMesh]

      // Add linked meshes from the group to the array
      const group = meshToFooterMapping[selectedMesh.name]?.group
      if (group) {
        Object.keys(meshToFooterMapping).forEach((meshName) => {
          if (meshToFooterMapping[meshName]?.group === group) {
            const linkedMesh = sceneRef.current.getObjectByName(meshName)
            if (linkedMesh && !meshesToBlink.includes(linkedMesh)) {
              meshesToBlink.push(linkedMesh)
            }
          }
        })
      }

      // Blink all meshes in the list
      meshesToBlink.forEach((mesh) => {
        if (mesh?.material && !mesh.userData.tempMaterialExists) {
          const originalMaterial = mesh.material // Save the original material
          const tempMaterial = originalMaterial.clone() // Clone the material for temporary use
          tempMaterial.color.set(0xffffff) // Set the temporary material to white

          // Assign the temporary material to the mesh
          mesh.material = tempMaterial

          // Mark the mesh as having a temp material
          mesh.userData.tempMaterialExists = true

          // Track this temporary material for disposal
          activeTempMaterials.push({ mesh, originalMaterial, tempMaterial })
        }
      })

      // Start a timer to dispose of all temporary materials after 500ms
      activeBlinkTimeout = setTimeout(() => {
        activeTempMaterials.forEach(({ mesh, originalMaterial, tempMaterial }) => {
          if (mesh) {
            mesh.material = originalMaterial // Restore original material
            tempMaterial.dispose() // Dispose of temporary material
            delete mesh.userData.tempMaterialExists // Remove the temp material marker
          }
        })
        activeTempMaterials = [] // Clear the active materials list
        activeBlinkTimeout = null // Reset the active timer
      }, 500)

      // Map the clicked mesh to the footer option
      const footerOption = meshToFooterMapping[selectedMesh.name]?.footerOption
      if (footerOption) {
        setActiveSelection(footerOption) // Activate the footer option
        scrollToActiveSelection() // Scroll to the active footer option
      }
    } else {
    }
  }

  const meshToFooterMapping = {
    // Screen Color Group
    motif_top: { footerOption: '3. Screen Color', group: 'screenGroup' },
    motif_bottom: { footerOption: '3. Screen Color', group: 'screenGroup' },
    scales_top: { footerOption: '3. Screen Color', group: 'screenGroup' },
    scales_bottom: { footerOption: '3. Screen Color', group: 'screenGroup' },
    holes_top: { footerOption: '3. Screen Color', group: 'screenGroup' },
    holes_bottom: { footerOption: '3. Screen Color', group: 'screenGroup' },
    windows_top: { footerOption: '3. Screen Color', group: 'screenGroup' },
    windows_bottom: { footerOption: '3. Screen Color', group: 'screenGroup' },
    stripes_top: { footerOption: '3. Screen Color', group: 'screenGroup' },
    stripes_bottom: { footerOption: '3. Screen Color', group: 'screenGroup' },
    stripes_bottomback: { footerOption: '3. Screen Color', group: 'screenGroup' },
    windows_bottomback: { footerOption: '3. Screen Color', group: 'screenGroup' },
    motif_bottomback: { footerOption: '3. Screen Color', group: 'screenGroup' },
    holes_bottomback: { footerOption: '3. Screen Color', group: 'screenGroup' },
    scales_bottomback: { footerOption: '3. Screen Color', group: 'screenGroup' },
    logo: { footerOption: '3. Screen Color', group: 'screenGroup' },
    logo_brass: { footerOption: '3. Screen Color', group: 'screenGroup' },
    custom_logo: { footerOption: '3. Screen Color', group: 'screenGroup' },

    // Fabric Color Group
    saati_top: { footerOption: '4. Fabric Color', group: 'fabricGroup' },
    saati_bottom: { footerOption: '4. Fabric Color', group: 'fabricGroup' },

    // Body Color Group
    body: { footerOption: '1. Body Color', group: 'bodyGroup' },

    // Microphone Type Group
    ribbon: { footerOption: '6. Microphone Type', group: 'microphoneGroup' },
    capsule: { footerOption: '6. Microphone Type', group: 'microphoneGroup' }
  }

  const fetchDataWithFileId = async (fileid) => {
    try {
      const response = await fetch(`https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/fileid/${fileid}`)
      if (!response.ok) throw new Error(`Failed to fetch data for fileid: ${fileid}`)
      const data = await response.json()
      return data
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    const fetchDataWithIdFromURL = async () => {
      const url = new URL(window.location.href)
      const fileid = url.pathname.split('/').pop() // Extract `fileid` from the URL

      if (fileid) {
        try {
          const data = await fetchDataWithFileId(fileid)

          if (data) {
            // Break out fetched values into variables
            const fetchedColor = data.color || ''
            const fetchedScreenType = data.screen_type || ''
            const fetchedScreenColor = data.screen_color || ''
            const fetchedFabricColor = data.fabric_color || ''
            const fetchedBottomScreenType = data.bottom_screen_type || ''
            const fetchedMicrophoneType = data.microphone_type || ''

            // Update state with fetched values
            setColor(fetchedColor)
            setScreenType(fetchedScreenType)
            setScreenColor(fetchedScreenColor)
            setFabricColor(fetchedFabricColor)
            setBottomScreenType(fetchedBottomScreenType)
            setMicrophoneType(fetchedMicrophoneType)
          } else {
          }
        } catch (error) {}
      } else {
      }
    }

    fetchDataWithIdFromURL()
  }, [])

  const lightPosition = [5, 5, 10]
  const canvasRef = useRef() // Define canvasRef here
  const [sceneReady, setSceneReady] = useState(false) // State to track scene readiness
  const modelRef = useRef()
  const [isAnimating, setIsAnimating] = useState(false)

  const componentRef = useRef(null)

  const [shopName, setShopName] = useState('your-store.myshopify.com')

  // "Body Color" draggable state and references
  const colorScrollRef = useRef(null) // Ref for color options container
  const [isColorDragging, setIsColorDragging] = useState(false) // Color dragging state
  const [startColorX, setStartColorX] = useState(0) // Starting X for color drag
  const [scrollLeftColor, setScrollLeftColor] = useState(0) // Initial scroll position for color drag

  // "Screen Color" draggable state and references
  const screenColorScrollRef = useRef(null) // Ref for screen color options container
  const [isScreenColorDragging, setIsScreenColorDragging] = useState(false) // Screen color dragging state
  const [startScreenColorX, setStartScreenColorX] = useState(0) // Starting X for screen color drag
  const [scrollLeftScreenColor, setScrollLeftScreenColor] = useState(0) // Initial scroll position for screen color

  useEffect(() => {
    if (!cameraRef.current || phoneType === 'unknown') return

    // 1) read the mid offset for this phone, e.g. 65
    const val = snapPercents[1]

    // 2) camera
    const dist = getCameraDistance(val)
    // e.g. if val=65, getCameraDistance(65) -> maybe 1.45 for promax or partial interpolation

    // set camera
    const direction = new THREE.Vector3()
    cameraRef.current.getWorldDirection(direction)
    cameraRef.current.position.copy(direction.multiplyScalar(-dist))
    cameraRef.current.updateProjectionMatrix()

    // 3) model Y
    updateModelPositionInRealTime(val)
    // This might set groupY with your interpolation logic
  }, [phoneType, sceneReady])

  useEffect(() => {
    if (sceneReady && orbitControlsRef.current) {
      // Delay the update until after all initial animations/updates have run.
      const timeoutId = setTimeout(() => {
        // Reset target – you might try logging the target before/after to see if it’s correct.
        orbitControlsRef.current.target.set(0, 0, 0)
        orbitControlsRef.current.update()
      }, 100) // 100ms delay (adjust if necessary)
      return () => clearTimeout(timeoutId)
    }
  }, [sceneReady])

  // Event handlers for "Body Color" dragging functionality
  const handleColorMouseDown = (e) => {
    setIsColorDragging(true)
    setStartColorX(e.pageX || e.touches[0].pageX)
    setScrollLeftColor(colorScrollRef.current.scrollLeft)
  }

  const handleColorMouseMove = (e) => {
    if (!isColorDragging) return
    const x = e.pageX || e.touches[0].pageX
    const walk = (x - startColorX) * 1.5 // Adjust scroll sensitivity here if needed
    colorScrollRef.current.scrollLeft = scrollLeftColor - walk
  }

  const handleColorMouseUpOrLeave = () => setIsColorDragging(false) // Reset dragging state

  // Event handlers for "Screen Color" dragging functionality
  const handleScreenColorMouseDown = (e) => {
    setIsScreenColorDragging(true)
    setStartScreenColorX(e.pageX || e.touches[0].pageX)
    setScrollLeftScreenColor(screenColorScrollRef.current.scrollLeft)
  }

  const handleScreenColorMouseMove = (e) => {
    if (!isScreenColorDragging) return
    const x = e.pageX || e.touches[0].pageX
    const walk = (x - startScreenColorX) * 1.5 // Adjust scroll sensitivity here if needed
    screenColorScrollRef.current.scrollLeft = scrollLeftScreenColor - walk
  }

  const handleScreenColorMouseUpOrLeave = () => setIsScreenColorDragging(false) // Reset dragging state

  const forceUpdateComponent = () => {
    if (componentRef.current) {
      componentRef.current.forceUpdate() // Call forceUpdate method on the component instance
    }
  }

  async function sendDataToServer() {
    const data = {
      receivedColor,
      receivedColorName,
      receivedScreenType,
      receivedScreenTypeName,
      receivedScreenColor,
      receivedScreenColorName,
      receivedFabricColor,
      receivedFabricColorName,
      receivedBottomScreenType,
      receivedMicrophoneType,
      receivedImageUrl
    }

    try {
      const response = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
    } catch (error) {}
  }

  useEffect(() => {
    const preventArrowScroll = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        event.preventDefault() // Prevent the default arrow key behavior
      }
    }

    window.addEventListener('keydown', preventArrowScroll)
    return () => window.removeEventListener('keydown', preventArrowScroll)
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = '' // Reset on cleanup
    }
  }, [])

  const swayCamera = () => {
    if (!cameraRef.current || isAnimating) {
      return // Prevent animation from starting again if it's already running
    }

    setIsAnimating(true)
    const initialRotationZ = cameraRef.current.rotation.z
    const swayAmount = Math.PI / 6 // This represents 30 degrees

    let cycles = 2 // Total back and forth motions

    const animateSway = (direction) => {
      if (cycles === 0) {
        new TWEEN.Tween(cameraRef.current.rotation)
          .to({ z: initialRotationZ }, 75)
          .easing(TWEEN.Easing.Quadratic.Out)
          .onComplete(() => setIsAnimating(false)) // Reset animation flag when done
          .start()
        return
      }

      const targetZ = direction ? initialRotationZ + swayAmount : initialRotationZ - swayAmount
      const tween = new TWEEN.Tween(cameraRef.current.rotation)
        .to({ z: targetZ }, 75)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onComplete(() => {
          cycles--
          animateSway(!direction) // Switch direction each call
        })

      tween.start()
    }

    animateSway(true) // Start the animation with the first sway to the right
  }

  const randomizeSelection = (options, weights = {}) => {
    let weightedOptions = []

    // Build an array containing each option repeated according to its weight
    Object.entries(options).forEach(([key, value]) => {
      const repeats = weights[key] || 1 // Use the weight if specified, or default to 1
      for (let i = 0; i < repeats; i++) {
        weightedOptions.push(key)
      }
    })

    // Select a random option from the weighted array
    const randomIndex = Math.floor(Math.random() * weightedOptions.length)
    return options[weightedOptions[randomIndex]]
  }

  const weights = {
    motif: 40, // Motif should come up 35% of the time
    stripes: 10,
    windows: 10,
    scales: 10,
    holes: 10
  }

  const newScreenType = randomizeSelection(Object.keys(screenTypeOptions), weights)

  const handleDiceClick = () => {
    // Randomize each property
    const newColor = randomizeSelection(Object.keys(colorOptions))
    const newScreenType = randomizeSelection(Object.keys(screenTypeOptions))
    const newScreenColor = randomizeSelection(Object.keys(screenColorOptions))
    const newFabricColor = randomizeSelection(Object.keys(fabricColorOptions))

    // Update state and send messages
    setColor(newColor)

    setScreenType(newScreenType)

    setScreenColor(newScreenColor)

    setFabricColor(newFabricColor)

    scrollToSelectedColor(newColor) // Scroll Body Color
    scrollToSelectedScreenColor(newScreenColor) // Scroll Screen Color

    // Optionally add any animation or visual effect
    swayCamera()
  }

  const [position, setPosition] = useState([0, 0, 5]) // Example position state

  // Example function to update position
  function updatePosition() {
    new TWEEN.Tween(position)
      .to([0, 0, 2], 2000)
      .onUpdate((newPos) => setPosition(newPos))
      .start()
  }

  useEffect(() => {
    updatePosition()
  }, [])

  useEffect(() => {
    if (sceneRef.current) {
      setEnvironmentMap(sceneRef.current)
    }
  }, [sceneRef.current]) // Ensure this runs only whfen the scene is initiallfy set

  const [isOrbiting, setIsOrbiting] = useState(false)
  const updateBodySelectability = (selectable) => {
    document.body.classList.toggle('no-select', !selectable)
  }

  useEffect(() => {
    if (!orbitControlsRef.current) return

    orbitControlsRef.current.mouseButtons = {
      LEFT: THREE.MOUSE.ROTATE,
      MIDDLE: null,
      RIGHT: null // Disable right-click dragging to rotate or pan
    }

    const controls = orbitControlsRef.current
    const onStart = () => {
      setIsOrbiting(true)
      updateBodySelectability(false)
    }
    const onEnd = () => {
      setIsOrbiting(false)
      updateBodySelectability(true)
    }

    controls.addEventListener('start', onStart)
    controls.addEventListener('end', onEnd)

    return () => {
      if (controls) {
        controls.removeEventListener('start', onStart)
        controls.removeEventListener('end', onEnd)
      }
    }
  }, [orbitControlsRef.current]) // Adding orbitControlsRef.current as a dependency

  const onCanvasCreated = ({ gl, scene, camera }) => {
    sceneRef.current = scene
    cameraRef.current = camera
    // Any other initialization code...
  }

  const handleSceneCreated = () => {
    setSceneReady(true) // Set scene readiness state to true
  }

  const handleColorSelect = (selectedValue) => {
    setColor(selectedValue) // Update the selected color in your state
    const selectedName = colorOptions[selectedValue] // Get the name associated with the selected value

    scrollToSelectedColor(selectedValue) // Center the selected color circle
  }

  const scrollToSelectedColor = () => {
    setTimeout(() => {
      const container = colorScrollRef.current // Reference to the color circles container
      const selectedCircle = container.querySelector('.color-circle.active') // Updated selector to find active circle

      if (container && selectedCircle) {
        container.scrollTo({
          left: selectedCircle.offsetLeft - container.offsetWidth / 2 + selectedCircle.offsetWidth / 2,
          behavior: 'smooth'
        })
      } else {
      }
    }, 0) // Delay to ensure DOM updates are complete
  }

  // Automatically scroll to the selected color circle when the color state changes
  useEffect(() => {
    scrollToSelectedColor(color)
  }, [color]) // Trigger when `color` changes

  const handleScreenColorSelect = (selectedValue) => {
    setScreenColor(selectedValue)
    const selectedName = screenColorOptions[selectedValue]

    scrollToSelectedScreenColor(selectedValue)
  }

  const scrollToSelectedScreenColor = () => {
    setTimeout(() => {
      const container = screenColorScrollRef.current // Ref to the container
      const activeElement = container.querySelector('.color-circle.active') // Match active element

      if (container && activeElement) {
        container.scrollTo({
          left: activeElement.offsetLeft - container.offsetWidth / 2 + activeElement.offsetWidth / 2,
          behavior: 'smooth'
        })
      } else {
      }
    }, 0) // Delay for DOM updates
  }

  useEffect(() => {
    scrollToSelectedScreenColor(screenColor)
  }, [screenColor])

  const normalizeColor = (colormatch) => {
    if (!colormatch) return color
    return colormatch.trim().toUpperCase() // Convert to uppercase and trim whitespace
  }

  const displayNames = {
    '1. Body Color': 'BODY', // Custom display name
    '2. Screen Color': 'SCREEN COLOR', // Custom display name
    '3. Screen Type': 'SCREEN', // Custom display name
    '4. Fabric Color': 'FABRIC', // Custom display name
    '5. Bottom Screen Type': 'LOGO', // Custom display name
    '6. Microphone Type': 'TYPE' // Custom display name

    // Add additional mappings here as needed
  }

  const scrollRef = useRef(null) // Ref to access the scrollable footer
  const [isDragging, setIsDragging] = useState(false)
  const [dragged, setDragged] = useState(false) // Track if a significant drag occurred
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setDragged(false) // Reset dragged state on new mouse down
    setStartX(e.pageX - scrollRef.current.offsetLeft)
    setScrollLeft(scrollRef.current.scrollLeft)
  }

  const handleOptionClick = (optionLabel) => {
    if (!dragged) {
      setIsVisible(false) // Start fade out to hide the tip
      setActiveSelection(optionLabel) // Update the active selection instantly

      setTimeout(() => {
        // Determine and set the new tip after a brief delay
        const tips = selectionTips[optionLabel] || []
        if (tips.length > 0) {
          const randomTipIndex = Math.floor(Math.random() * tips.length)
          setActiveTip(tips[randomTipIndex])
        } else {
          setActiveTip('No tips available for this selection.')
        }
        setIsVisible(true) // Fade in the new tip
      }, 300) // Match this delay with the CSS fade duration for a smooth transition

      // Center the color circles based on the selected option
      if (optionLabel === '1. Body Color') {
        scrollToSelectedColor(color)
      } else if (optionLabel === '2. Screen Color') {
        scrollToSelectedScreenColor(screenColor)
      }
    }
    setDragged(false) // Reset after processing the click
  }

  const handleMouseMove = (e) => {
    if (!isDragging) return

    e.preventDefault() // Prevent other actions like text selection
    const x = e.pageX - scrollRef.current.offsetLeft
    const walk = (x - startX) * 2 // Adjust this value based on your needs

    if (Math.abs(walk) > 5) {
      // You can adjust this threshold
      setDragged(true)
    }

    scrollRef.current.scrollLeft = scrollLeft - walk
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const [activeSelection, setActiveSelection] = useState('1. Body Color')

  // Cycle through options in either direction
  const cycleOptions = (options, current, direction) => {
    const keys = Object.keys(options)
    const currentIndex = keys.indexOf(current)
    const nextIndex = (currentIndex + (direction === 'next' ? 1 : -1) + keys.length) % keys.length
    return keys[nextIndex]
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        const direction = event.key === 'ArrowRight' ? 'next' : 'prev'

        switch (activeSelection) {
          case '1. Body Color':
            setColor((prev) => cycleOptions(colorOptions, prev, direction))
            break
          case '2. Screen Type':
            setScreenType((prev) => cycleOptions(screenTypeOptions, prev, direction))
            break
          case '3. Screen Color':
            setScreenColor((prev) => cycleOptions(screenColorOptions, prev, direction))
            break
          case '4. Fabric Color':
            setFabricColor((prev) => cycleOptions(fabricColorOptions, prev, direction))
            break
          case '6. Microphone Type':
            setMicrophoneType((prev) => cycleOptions(microphoneTypeOptions, prev, direction))
            break

          default:
            break
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [activeSelection, color, screenType, screenColor]) // Include all dependencies that influence the effect

  const selectionTips = {
    '1. Body Color': ['Choosing a lighter color can highlight the contours of the design.', 'Darker colors might hide fingerprints and smudges better.'],
    '2. Screen Type': ['Screens with larger openings can enhance sound clarity.', 'Tighter screens might be better for more controlled sound output.'],
    '3. Screen Color': ['Match the screen color with the body for a uniform look.', 'Contrasting colors can make the screen stand out more visually.'],
    '4. Fabric Color': ['Light fabric colors are great for vibrant setups.', 'Darker fabrics can give a more sophisticated look.'],
    '5. Bottom Screen Type': ['Adding a logo can increase brand visibility.', 'Patterned screens can add a unique touch to your design.'],
    '6. Microphone Type': [
      'Condenser microphones are excellent for capturing vocal subtleties.',
      'Dynamic microphones are better for high-volume environments.'
    ]
  }

  const tipsArray = [
    'Six month interest-free payments when you choose Shop Pay.',
    'Free shipping on orders over $150.',
    'Sign up for our newsletter and get 10% off.',
    'New customers receive a free gift with their first purchase.'
    // ... any number of tips you want to cycle through
  ]

  const [isVisible, setIsVisible] = useState(true)

  const initialTip = selectionTips[Object.keys(selectionTips)[0]][0] || 'Welcome to the tips section!' // Default tip to avoid empty content
  const [activeTip, setActiveTip] = useState(initialTip)

  useEffect(() => {
    const changeTip = () => {
      setIsVisible(false) // Start fade out

      setTimeout(() => {
        const tips = selectionTips[activeSelection] || [] // Ensure it defaults to an empty array
        if (tips.length > 0) {
          const randomTipIndex = Math.floor(Math.random() * tips.length)
          setActiveTip(tips[randomTipIndex]) // Set new tip from available tips
        } else {
          setActiveTip('No tips available for this selection.') // Default message when no tips are available
        }
        setIsVisible(true) // Start fade in
      }, 500)
    }

    const intervalId = setInterval(changeTip, 8000)
    return () => clearInterval(intervalId)
  }, [activeSelection])

  useEffect(() => {
    const selectionOrder = ['1. Body Color', '2. Screen Type', '3. Screen Color', '4. Fabric Color', '5. Bottom Screen Type', '6. Microphone Type']

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault() // Prevent the default arrow key scrolling behavior
        const currentIndex = selectionOrder.indexOf(activeSelection)
        const newIndex =
          event.key === 'ArrowDown' ? (currentIndex + 1) % selectionOrder.length : (currentIndex - 1 + selectionOrder.length) % selectionOrder.length
        setActiveSelection(selectionOrder[newIndex])
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [activeSelection]) // Dependency array to update if activeSelection changes

  const changeSelectionUp = () => {
    const index = Object.keys(selectionContent).indexOf(activeSelection)
    if (index > 0) {
      setActiveSelection(Object.keys(selectionContent)[index - 1])
    }
  }

  const changeSelectionDown = () => {
    const index = Object.keys(selectionContent).indexOf(activeSelection)
    if (index < Object.keys(selectionContent).length - 1) {
      setActiveSelection(Object.keys(selectionContent)[index + 1])
    }
  }

  const selectionRef = useRef(null)
  const scrollIndicatorRef = useRef(null)

  const navigateSelection = (direction) => {
    const selectionOrder = ['1. Body Color', '2. Screen Type', '3. Screen Color', '4. Fabric Color', '5. Bottom Screen Type', '6. Microphone Type']
    const currentIndex = selectionOrder.indexOf(activeSelection)
    let newIndex
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % selectionOrder.length
    } else {
      newIndex = (currentIndex - 1 + selectionOrder.length) % selectionOrder.length
    }
    setActiveSelection(selectionOrder[newIndex])
    scrollToActiveSelection()
  }

  const scrollToActiveSelection = () => {
    // Ensure the update happens after state change renders
    setTimeout(() => {
      const activeElement = document.querySelector('.footer-option.active')
      if (activeElement && scrollRef.current) {
        scrollRef.current.scrollTo({
          left: activeElement.offsetLeft - scrollRef.current.offsetWidth / 2 + activeElement.offsetWidth / 2,
          behavior: 'smooth'
        })
      }
    }, 0)
  }

  useEffect(() => {
    scrollToActiveSelection()
  }, [activeSelection]) // This will trigger when activeSelection changes

  const handleBottomScreenTypeSelect = (value, label) => {
    setBottomScreenType(value)
  }

  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/7')
        if (!response.ok) {
          throw new Error(`Failed to fetch data: status ${response.status}`)
        }
        const data = await response.json()
      } catch (error) {
        setError(error)
      }
    }

    fetchData()
  }, [])

  const id = useID() // Get ID from context

  useEffect(() => {
    const fetchDataWithId = async () => {
      const url = new URL(window.location.href)
      const queryId = url.searchParams.get('id') // Check for ID in query parameters (e.g., ?id=12)
      const pathId = url.pathname.split('/').pop() // Check for ID in the URL path (e.g., /products/custom-mic-test/12)
      const finalId = queryId || pathId // Use query ID if available, otherwise fallback to path ID

      if (finalId) {
        try {
          const response = await fetch(`https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/variables/${finalId}`)
          if (!response.ok) throw new Error(`Failed to fetch data: status ${response.status}`)

          const data = await response.json()
          const fetchedScreenColor = data.screen_color
          const fetchedBodyColor = data.color
          const fetchedScreenType = data.screen_type
          const fetchedFabricColor = data.fabric_color
          const fetchedBottomScreenType = data.bottom_screen_type
          const fetchedMicrophoneType = data.microphone_type

          setScreenColor(fetchedScreenColor)
          setColor(fetchedBodyColor)
          setScreenType(fetchedScreenType)
          setFabricColor(fetchedFabricColor)
          setBottomScreenType(fetchedBottomScreenType)
          setMicrophoneType(fetchedMicrophoneType)
        } catch (error) {}
      } else {
      }
    }

    fetchDataWithId()
  }, [])

  const selectionContent = {
    '1. Body Color': (
      <div className="option-content">
        <div className="header-and-selected">
          <div className="option-header">
            <h1>Body</h1>
          </div>
          <div className="selected-option">
            <div className="selected-color">{colorOptions[color]}</div>
          </div>
        </div>
        <div className="color-options">
          <div
            className="color-options-container"
            ref={colorScrollRef}
            onMouseDown={handleColorMouseDown}
            onMouseMove={handleColorMouseMove}
            onMouseUp={handleColorMouseUpOrLeave}
            onMouseLeave={handleColorMouseUpOrLeave}>
            {Object.entries(colorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${color === value ? 'active' : ''}`}
                onClick={() => {
                  handleColorSelect(value)
                  const selectedName = colorOptions[value]
                }}
                style={{ backgroundColor: value }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),

    '2. Screen Color': (
      <div>
        <div className="option-content"></div>

        <div className="header-and-selected">
          <div className="option-header">
            <h1>Screen</h1>
          </div>
          <div className="selected-option">
            <div className="selected-color">{screenColorOptions[screenColor]}</div>
          </div>
        </div>
        <div className="color-options">
          <div
            className="color-options-container"
            ref={screenColorScrollRef}
            onMouseDown={handleScreenColorMouseDown}
            onMouseMove={handleScreenColorMouseMove}
            onMouseLeave={handleScreenColorMouseUpOrLeave}
            onMouseUp={handleScreenColorMouseUpOrLeave}>
            {Object.entries(screenColorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${screenColor === value ? 'active' : ''}`}
                onClick={() => {
                  handleScreenColorSelect(value)
                  const selectedName = screenColorOptions[value] // Inline constant for clarity
                }}
                style={{ backgroundColor: value }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    '3. Screen Type': (
      <div>
        <div className="option-content">
          <div className="header-and-selected">
            <div className="option-header">
              <h1>Screen</h1>
            </div>
            <div className="selected-option">
              <div className="selected-color">{screenTypeOptions[screenType].label}</div>
            </div>
          </div>

          <div className="color-options">
            <div className="screen-options-container">
              {Object.entries(screenTypeOptions).map(([value, { label, iconUrl }]) => (
                <div
                  key={value}
                  className="screen-type-option"
                  onClick={() => {
                    setScreenType(value)
                    const screenTypeLabel = label
                  }}>
                  <div className="icon-container">
                    <img src={iconUrl} alt={label} className={`screen-type-icon ${screenType === value ? 'active' : ''}`} />
                    {screenType === value && (
                      <svg
                        className="highlight-circle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#6186f7"
                        strokeWidth="1"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ width: '54px', height: '54px', pointerEvents: 'none' }}>
                        ><rect x="1.5" y="1.5" width="21" height="21" rx="4" ry="4"></rect>
                      </svg>
                    )}
                  </div>
                  <span className="screen-type-label"></span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    '4. Fabric Color': (
      <div>
        <div className="option-content"></div>

        <div className="header-and-selected">
          <div className="option-header">
            <h1>Fabric</h1>
          </div>
          <div className="selected-option">
            <div className="selected-color">{fabricColorOptions[fabricColor]}</div>
          </div>
        </div>

        <div className="color-options">
          <div className="color-options-container">
            {Object.entries(fabricColorOptions).map(([value, name]) => (
              <div
                key={value}
                className={`color-circle ${fabricColor === value ? 'active' : ''}`}
                onClick={() => {
                  setFabricColor(value)
                  const selectedName = fabricColorOptions[value]
                  const selectedValue = value // Define selectedValue
                }}
                style={{
                  backgroundColor: value,
                  border: value === '#FFFFFF' ? '2px solid black' : 'none' // Adds black border only for white color
                }}
                title={name}>
                {/* Optional content inside the circle */}
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
    // ... Add content for options 5 to 10 here
    '5. Bottom Screen Type': (
      <div className="option-content">
        <div className="header-and-selected">
          <div className="option-header">
            <h1>Logo</h1>
          </div>
          <div className="selected-option">
            <div className="selected-color">{bottomScreenTypeOptions[bottomScreenType].label}</div>
          </div>
        </div>
        <div className="bottom-screen-options-container">
          {Object.entries(bottomScreenTypeOptions).map(([value, { label, iconUrl }]) => {
            // For "Add Pattern Screen", override with the icon from the selected screen type.
            const finalIconUrl = value === 'Add Pattern Screen' ? screenTypeOptions[screenType]?.iconUrl || iconUrl : iconUrl

            return (
              <div
                key={value}
                className={`bottom-screen-type-option ${bottomScreenType === value ? 'active' : ''}`}
                onClick={() => handleBottomScreenTypeSelect(value, label)}>
                <div className="icon-container">
                  <img src={finalIconUrl} alt={label} className="bottom-screen-type-icon" />
                  {bottomScreenType === value && (
                    <svg
                      className="highlight-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#6186f7"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{ width: '54px', height: '54px', pointerEvents: 'none' }}>
                      <rect x="1.5" y="1.5" width="21" height="21" rx="4" ry="4"></rect>
                    </svg>
                  )}
                </div>
                <span className="bottom-screen-type-label"></span>
              </div>
            )
          })}
        </div>
      </div>
    ),

    // ...

    '6. Microphone Type': (
      <div>
        <div className="option-content">
          <div className="header-and-selected">
            <div className="option-header">
              <h1>Mic Type</h1>
            </div>
            <div className="selected-option">
              <div className="selected-color">{microphoneTypeOptions[microphoneType].label}</div>
            </div>
          </div>
          <div className="microphone-options-container">
            {Object.entries(microphoneTypeOptions).map(([value, { label, iconUrl }]) => (
              <div
                key={value}
                className={`microphone-type-option ${microphoneType === value ? 'active' : ''}`}
                onClick={() => {
                  setMicrophoneType(value)
                  const microphoneTypeLabel = label
                }}>
                <div className="icon-container">
                  <img src={iconUrl} alt={label} className="microphone-type-icon" style={{ width: '42px', height: '42px' }} />
                  {microphoneType === value && (
                    <svg
                      className="highlight-circle"
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="50"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#6186f7"
                      strokeWidth="1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      style={{ width: '54px', height: '54px', pointerEvents: 'none' }}>
                      ><rect x="1.5" y="1.5" width="21" height="21" rx="4" ry="4"></rect>
                    </svg>
                  )}
                </div>
                <span className="microphone-type-label"></span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  const handleSelectionClick = (optionLabel) => {
    setActiveSelection(optionLabel)
  }

  AWS.config.update({
    accessKeyId: 'AKIAQ3EGPYYCUGGVRPFF',
    secretAccessKey: 'seX4Ppgbb39rfDOfBt0S5a5dCQehNZTSJ8gO5lSn',
    region: 'us-west-1' // e.g., 'us-west-2'
  })

  const s3 = new AWS.S3()

  const INITIAL_CAMERA_DISTANCE = 1.1 // Set this to your desired initial distance
  const INITIAL_CAMERA_POSITION = [0, 0.35, INITIAL_CAMERA_DISTANCE]
  const INITIAL_CAMERA_ROTATION = [0, 0, 0] // Assuming no initial rotation

  // Function to update camera distance based on drag interaction
  const updateCameraDistance = (newDistance) => {
    if (cameraRef.current) {
      const direction = new THREE.Vector3()
      cameraRef.current.getWorldDirection(direction)
      const newPosition = direction.multiplyScalar(-newDistance)
      cameraRef.current.position.copy(newPosition)
      cameraRef.current.updateProjectionMatrix()
    }
  }

  const [isCameraMoving, setIsCameraMoving] = useState(false)

  // Function to reset camera to initial position while preserving current distance
  const resetCamera = () => {
    if (cameraRef.current && orbitControlsRef.current) {
      setIsCameraMoving(true)
      const currentDistance = cameraRef.current.position.length()
      const targetPosition = new THREE.Vector3(0, 0, currentDistance)

      new TWEEN.Tween(cameraRef.current.position)
        .to(targetPosition, 1000)
        .easing(TWEEN.Easing.Cubic.InOut)
        .onUpdate(() => {
          cameraRef.current.lookAt(0, 0, 0)
        })
        .onComplete(() => {
          orbitControlsRef.current.target.set(0, 0, 0)
          orbitControlsRef.current.update()
          setIsCameraMoving(false)
        })
        .start()
    }
  }

  const rotateCameraBehind = () => {
    if (!cameraRef.current || !orbitControlsRef.current || isCameraMoving) return

    setIsCameraMoving(true)
    const currentDistance = cameraRef.current.position.length()
    const targetPosition = new THREE.Vector3(0, 0, -currentDistance)

    new TWEEN.Tween(cameraRef.current.position)
      .to(targetPosition, 2000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(() => {
        cameraRef.current.lookAt(0, 0, 0)
      })
      .onComplete(() => {
        orbitControlsRef.current.update()
        setIsCameraMoving(false)
      })
      .start()
  }

  const resetCameraSide = () => {
    if (!cameraRef.current || !orbitControlsRef.current || isCameraMoving) return

    setIsCameraMoving(true)
    const currentDistance = cameraRef.current.position.length()
    const angle = Math.PI / 4 // 45 degrees for side view
    const targetPosition = new THREE.Vector3(currentDistance * Math.cos(angle), 0, currentDistance * Math.sin(angle))

    new TWEEN.Tween(cameraRef.current.position)
      .to(targetPosition, 2000)
      .easing(TWEEN.Easing.Cubic.InOut)
      .onUpdate(() => {
        cameraRef.current.lookAt(0, 0, 0)
      })
      .onComplete(() => {
        orbitControlsRef.current.update()
        setIsCameraMoving(false)
      })
      .start()
  }

  function generateSKU({ micType, bodyColor, screenType, screenColor, fabricColor, logoType }) {
    const micCode = options.micType[micType] || ''
    const bodyCode = options.colors[bodyColor] ?? ''
    const screenCode = options.screenType[screenType] || ''
    const screenColorCode = options.colors[screenColor] ?? ''
    const fabricCode = options.colors[fabricColor] ?? ''
    const logoCode = options.logoType[logoType] || ''

    return `${micCode}-${bodyCode}-${screenCode}-${screenColorCode}-${fabricCode}-${logoCode}`
  }

  const openCartDrawer = () => {
    document.dispatchEvent(new CustomEvent('theme:open-cart-drawer', { bubbles: true, cancelable: false }))
  }

  function setLoadingState(button) {
    button.classList.add('loading')
    button.disabled = true
  }

  function clearLoadingState(button) {
    button.classList.remove('loading')
    button.disabled = false
  }

  const addToCart = async () => {
    const addToCartButton = document.querySelector('.add-to-cart')
    // Start loading state
    setLoadingState(addToCartButton)

    const selectedOptions = {
      micType: microphoneType,
      bodyColor: colorOptions[color],
      screenType: screenTypeOptions[screenType]?.label,
      screenColor: screenColorOptions[screenColor],
      fabricColor: fabricColorOptions[fabricColor],
      logoType: bottomScreenType // Assuming this represents the logo type
    }

    const sku = generateSKU(selectedOptions)

    let awsThumbnailUrl = '' // Declare awsThumbnailUrl in the broader scope of addToCart
    const placeholderImageUrl = 'https://ohmaworld.com/cdn/shop/products/ZAp4bjlO_91171723-896c-43c7-b23e-31a06fdd4f9d.png' // Placeholder image URL

    if (!sceneRef.current || !cameraRef.current) {
      return
    }

    const saatiMesh = sceneRef.current.getObjectByName('saati_top')
    const topScreenMesh = sceneRef.current.getObjectByName(`${screenType}_top`)

    if (saatiMesh) {
      saatiMesh.visible = true
      saatiMesh.material.opacity = 1
      saatiMesh.material.transparent = true
    }

    if (topScreenMesh) {
      topScreenMesh.visible = true
      topScreenMesh.material.opacity = 1
      topScreenMesh.material.transparent = true
    }

    // Create and use the thumbnail camera
    const thumbnailCamera = createThumbnailCamera(cameraRef.current)

    sceneRef.current.traverse((object) => {
      if ((object instanceof THREE.DirectionalLight || object instanceof THREE.SpotLight) && !object.castShadow) {
        object.castShadow = true
        // Optionally, adjust shadow map settings for performance
        object.shadow.mapSize.width = 512 // Default is 512
        object.shadow.mapSize.height = 512 // Default is 512
      }
      if (object.isMesh) {
        object.receiveShadow = true
      }
    })

    setEnvironmentMap(sceneRef.current)
    // Make sure your renderer is configured to handle shadows

    // Create a high-resolution offscreen canvas
    const scale = 1 // Supersampling factor for higher resolution rendering
    const highResWidth = 180 * scale
    const highResHeight = 180 * scale
    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = highResWidth
    offscreenCanvas.height = highResHeight

    // Set the thumbnail camera to the initial position and rotation
    thumbnailCamera.position.set(...INITIAL_CAMERA_POSITION)
    thumbnailCamera.lookAt(sceneRef.current.position) // Assuming you want to look at the center of the scene
    thumbnailCamera.updateProjectionMatrix()

    // The rest of your rt logic follows here...

    // Use thumbnailCamera for rendering without re-declaring it
    thumbnailCamera.aspect = highResWidth / highResHeight
    thumbnailCamera.updateProjectionMatrix()

    // Use the WebGLRenderer with the offscreen canvas
    const renderer = new THREE.WebGLRenderer({ canvas: offscreenCanvas, antialias: true })
    renderer.setSize(highResWidth, highResHeight)
    renderer.setClearColor(0x000000, 0) // Optional: Transparent background

    // Adjust the camera for the high-resolution rendering

    thumbnailCamera.aspect = highResWidth / highResHeight
    thumbnailCamera.updateProjectionMatrix()

    const closerZ = thumbnailCamera.position.z - 0.35
    thumbnailCamera.position.z = closerZ

    const lowerY = thumbnailCamera.position.y + 0.1 // Adjust this value as needed
    thumbnailCamera.position.y = lowerY

    const backdropGeometry = new THREE.PlaneGeometry(100, 100) // Adjust size as needed
    const backdropMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' }) // Blue color for the backdrop
    const backdropMesh = new THREE.Mesh(backdropGeometry, backdropMaterial)
    backdropMesh.position.z = -5 // Set the position behind the model, adjust as needed
    sceneRef.current.add(backdropMesh)

    const groundGeometry = new THREE.PlaneGeometry(100, 100) // Large enough to act as a ground
    const groundMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' }) // Blue color
    const groundMesh = new THREE.Mesh(groundGeometry, groundMaterial)
    groundMesh.rotation.x = -Math.PI / 2 // Rotate the plane to be horizontal
    groundMesh.position.y = -0.499 // Adjust based on the bottom of your model
    sceneRef.current.add(groundMesh)

    // Render the scene with the high-resolution camera
    renderer.render(sceneRef.current, thumbnailCamera)
    sceneRef.current.remove(groundMesh)
    sceneRef.current.remove(backdropMesh)

    // Create another canvas to perform the image resizing
    const resizeCanvas = document.createElement('canvas')
    resizeCanvas.width = 180 // Target dimensions
    resizeCanvas.height = 180
    const ctx = resizeCanvas.getContext('2d')

    // Draw the high-resolution image onto the resizing canvas, effectively resizing it
    ctx.drawImage(offscreenCanvas, 0, 0, resizeCanvas.width, resizeCanvas.height)

    // Scene setup and rendering logic here
    resizeCanvas.toBlob(async (blob) => {
      const fileid = Date.now() // Get the timestamp
      const fileKey = `${fileid}_microphone_thumbnail.png`

      const params = {
        Bucket: 'ohmacustomizerphotos',
        Key: fileKey,
        Body: blob,
        ContentType: 'image/png',
        ACL: 'public-read'
      }

      try {
        // Step 1: Upload to S3
        const s3UploadResult = await new Promise((resolve, reject) => {
          s3.upload(params, (err, data) => {
            if (err) reject(err)
            else resolve(data.Location)
          })
        })
        awsThumbnailUrl = s3UploadResult

        // Step 2: Send data to Heroku
        const herokuResponse = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            color: color,
            screenType: screenType,
            screenColor: screenColor,
            fabricColor: fabricColor,
            bottomScreenType: bottomScreenType,
            microphoneType: microphoneType,
            Pic: awsThumbnailUrl,
            fileid: fileid // Add fileId to Heroku payload yeah
          })
        })

        if (!herokuResponse.ok) throw new Error('Failed to send data to Heroku')
        const herokuData = await herokuResponse.json()

        // Extract the `id` from the response
        const id = herokuData.data?.id
        if (!id) {
          throw new Error('No ID returned from Heroku.')
        }

        const shareableLink = `https://ohmaworld.com/products/custom-mic-test/${fileid}`

        // Update the browser's URL to include the ID
        window.history.pushState({}, '', shareableLink)

        // Step 3: Send data to Shopify
        const shopifyProperties = {
          Color: colorOptions[color],
          'Screen Type': screenTypeOptions[screenType]?.label,
          'Screen Color': screenColorOptions[screenColor],
          'Fabric Color': fabricColorOptions[fabricColor],
          'Bottom Screen Type': bottomScreenType,
          'Microphone Type': microphoneType,
          Pic: awsThumbnailUrl,
          fileid: fileid, // Add fileId to Heroku payload
          SKU: sku // Add SKU to properties
        }

        const shopifyResponse = await fetch('/cart/add.js', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            items: [
              {
                id: '43167140184164', //variant id for shopify page
                quantity: 1,
                properties: shopifyProperties
              }
            ]
          })
        })

        if (!shopifyResponse.ok) throw new Error('Failed to add item to Shopify cart')
        const shopifyData = await shopifyResponse.json()

        document.dispatchEvent(new CustomEvent('theme:cartchanged', { bubbles: true, cancelable: false }))

        // Add a slight delay before refreshing the cart content
        setTimeout(() => {
          openCartDrawer() // Open the drawer right away
        }, 10) // Adjust delay if necessary
      } catch (error) {
      } finally {
        // End loading state
        clearLoadingState(addToCartButton)
      }
    }, 'image/png')
  }

  // Define the function to update the cart item with the custom image
  function updateCartItemWithCustomImage(imageUrl) {
    setTimeout(() => {
      const cartItem = document.querySelector('.cart-item') // Adjust if needed
      if (cartItem) {
        const existingImage = cartItem.querySelector('.cart-item__image img')

        if (existingImage) {
          existingImage.src = imageUrl
        } else {
          const newImage = document.createElement('img')
          newImage.src = imageUrl
          newImage.alt = 'Custom Image'
          const imageContainer = cartItem.querySelector('.cart-item__image')
          if (imageContainer) {
            imageContainer.appendChild(newImage)
          } else {
          }
        }
      } else {
      }
    }, 10) // Adjust timeout as needed
  }

  const shareProduct = async () => {
    try {
      // Generate a file ID immediately (without waiting for thumbnail generation)
      const fileid = Date.now() // Unique timestamp-based ID

      // Send initial data to Heroku without waiting for the thumbnail
      const initialData = {
        color: color,
        screenType: screenType,
        screenColor: screenColor,
        fabricColor: fabricColor,
        bottomScreenType: bottomScreenType,
        microphoneType: microphoneType,
        fileid: fileid // Include the generated file ID
      }

      const herokuResponse = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(initialData)
      })

      if (!herokuResponse.ok) {
        throw new Error('Failed to send initial data to Heroku')
      }

      const herokuData = await herokuResponse.json()

      // Generate the shareable link immediately
      const shareableLink = `https://ohmaworld.com/products/custom-mic-test/${fileid}`

      // Trigger sharing functionality
      if (navigator.share) {
        await navigator.share({
          title: 'Check out this custom microphone!',
          url: shareableLink
        })
      } else {
        navigator.clipboard.writeText(shareableLink)
        alert('Link copied to clipboard: ' + shareableLink)
      }

      // Generate and upload the thumbnail asynchronously (doesn't block sharing)
      generateThumbnailAndUpload(fileid)
    } catch (error) {}
  }

  // Function to generate and upload the thumbnail
  const generateThumbnailAndUpload = async (fileid) => {
    try {
      const thumbnailUrl = await generateThumbnail() // Generate the thumbnail
      if (!thumbnailUrl) {
        throw new Error('Failed to generate thumbnail')
      }

      // Upload the thumbnail to Heroku after generation
      const thumbnailData = {
        fileid: fileid,
        thumbnailUrl: thumbnailUrl // Add the AWS URL after upload
      }

      const herokuResponse = await fetch('https://customizer-test-nodejs-4c6a5bfd6dbd.herokuapp.com/api/receive-thumbnail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(thumbnailData)
      })

      if (!herokuResponse.ok) {
        throw new Error('Failed to send thumbnail to Heroku')
      }
    } catch (error) {}
  }

  const generateThumbnail = async () => {
    if (!sceneRef.current || !cameraRef.current) {
      return null
    }

    // Ensure the model is visible
    const saatiMesh = sceneRef.current.getObjectByName('saati_top')
    const topScreenMesh = sceneRef.current.getObjectByName(`${screenType}_top`)

    if (saatiMesh) {
      saatiMesh.visible = true
      saatiMesh.material.opacity = 1
      saatiMesh.material.transparent = true
    } else {
    }

    if (topScreenMesh) {
      topScreenMesh.visible = true
      topScreenMesh.material.opacity = 1
      topScreenMesh.material.transparent = true
    } else {
    }

    // Create and configure the thumbnail camera
    const thumbnailCamera = createThumbnailCamera(cameraRef.current)
    thumbnailCamera.aspect = 1 // Square thumbnail
    thumbnailCamera.updateProjectionMatrix()

    // Reset camera position and rotation to the initial values
    thumbnailCamera.position.set(...INITIAL_CAMERA_POSITION) // Replace with your actual initial position
    thumbnailCamera.rotation.set(...INITIAL_CAMERA_ROTATION) // Replace with your actual initial rotation
    thumbnailCamera.lookAt(sceneRef.current.position) // Ensure the camera is looking at the model
    thumbnailCamera.updateProjectionMatrix()

    const offscreenCanvas = document.createElement('canvas')
    offscreenCanvas.width = 180
    offscreenCanvas.height = 180

    const renderer = new THREE.WebGLRenderer({
      canvas: offscreenCanvas,
      antialias: true
    })
    renderer.setSize(180, 180)
    renderer.setClearColor(0xffffff, 1) // Ensure white background

    // Optional: Add a backdrop for better visibility
    const backdropGeometry = new THREE.PlaneGeometry(100, 100)
    const backdropMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff' })
    const backdropMesh = new THREE.Mesh(backdropGeometry, backdropMaterial)
    backdropMesh.position.z = -5
    sceneRef.current.add(backdropMesh)

    // Render the scene
    renderer.render(sceneRef.current, thumbnailCamera)

    // Remove backdrop after rendering
    sceneRef.current.remove(backdropMesh)

    // Resize and convert the canvas to a Blob
    const resizeCanvas = document.createElement('canvas')
    resizeCanvas.width = 180
    resizeCanvas.height = 180
    const ctx = resizeCanvas.getContext('2d')
    ctx.drawImage(offscreenCanvas, 0, 0, resizeCanvas.width, resizeCanvas.height)

    const blob = await new Promise((resolve) => resizeCanvas.toBlob(resolve, 'image/png'))

    // Upload the Blob to S3
    const fileid = Date.now() // Get the timestamp
    const fileKey = `${fileid}_microphone_thumbnail.png` // Use the same timestamp for fileKey

    const params = {
      Bucket: 'ohmacustomizerphotos',
      Key: fileKey,
      Body: blob,
      ContentType: 'image/png',
      ACL: 'public-read'
    }

    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          // Call setShareImage with the static URL after the thumbnail generation
          setShareImage('https://ohmacustomizerphotos.s3.us-west-1.amazonaws.com/1730936270086_shared_thumbnail.png')

          resolve(data.Location)
        }
      })
    })
  }

  const setShareImage = (url) => {
    try {
      const ogImageTag = document.querySelector('meta[property="og:image"]')
      const ogImageSecureTag = document.querySelector('meta[property="og:image:secure_url"]')
      if (ogImageTag && ogImageSecureTag) {
        ogImageTag.setAttribute('content', url)
        ogImageSecureTag.setAttribute('content', url)
      } else {
      }
    } catch (error) {}
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const takeScreenshot = () => {
    const canvas = canvasRef.current
    if (canvas) {
      const url = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = url
      link.download = 'OhmaWorldMicrophone.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  function ScreenshotControl({ canvasRef }) {
    const takeScreenshot = () => {
      const canvas = canvasRef.current
      const url = canvas.toDataURL('image/png')
      const link = document.createElement('a')
      link.href = url
      link.download = 'OhmaWorldMicrophone.png'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    return <div style={{ position: 'absolute', zIndex: 1, top: '20px', right: '20px' }}></div>
  }

  // Inside your component
  useEffect(() => {
    // Prepare the initial states for each selection to Shopify, except "Body Color"
    const selections = [
      { property: 'Bottom Screen Type', value: capitalizeFirstLetter(bottomScreenType) },
      { property: 'Microphone Type', value: capitalizeFirstLetter(microphoneType) }
    ]

    const bodyColorName = colorOptions[color] // Retrieve the display name for "Body Color
    const screenColorName = screenColorOptions[screenColor] // Directly retrieve the display name
    const fabricColorName = fabricColorOptions[fabricColor] // Retrieve the display name for "Fabric Color"
    const screenTypeName = screenTypeOptions[screenType].label // Fetch the label using the key

    // Then, send the initial states for other selections
    selections.forEach((selection) => {})
  }, []) // The empty dependency array ensures this effect runs once on mount.

  const sendColorToShopify = (selectedColor) => {}

  // Repeat a similar structure for sending other types of selections
  const sendScreenTypeToShopify = useMemo(() => debounce((selectedScreenType) => {}, 500), [])

  const sendColorDebounced = useMemo(() => debounce(sendColorToShopify, 500), [])

  const sendCurrentStateToParent = () => {
    const currentState = {
      color,
      screenType,
      screenColor,
      fabricColor,
      bottomScreenType,
      microphoneType
    }
  }

  const sendStateDebounced = useMemo(
    () => debounce(sendCurrentStateToParent, 500),
    [sendCurrentStateToParent, color, screenType, screenColor, fabricColor, bottomScreenType, microphoneType]
  )

  const handleSelectionChange = (newSelection) => {
    setActiveSelection(newSelection)
    const tips = selectionTips[newSelection]
    setActiveTip(tips[0]) // Immediately show the first tip from the new selection
  }

  useEffect(() => {
    if (sceneReady) {
      resetCameraSide()
      setActiveDot('dots-side')
    }
  }, [sceneReady]) // Trigger when sceneReady changes to true

  return (
    <>
      {sceneReady && <ScreenshotControl canvasRef={canvasRef} />}

      <div className="maincontainer">
        <div className="modelcontainer">
          <div className="app-container">
            {!id && !sceneReady && <LoadingIndicator />}
            <Canvas
              shadows
              gl={{
                preserveDrawingBuffer: true,
                gammaOutput: true,
                gammaFactor: 1,
                toneMapping: THREE.ACESFilmicToneMapping,
                toneMappingExposure: 1
              }}
              ref={canvasRef}
              onCreated={({ gl, scene, camera }) => {
                sceneRef.current = scene
                cameraRef.current = camera

                // Set the camera's position along the z-axis using the computed middle distance.
                camera.position.set(0, 0, initialDistance)
                camera.lookAt(0, 0, 0)
                camera.updateProjectionMatrix()
                // Optionally update OrbitControls target if needed:
                requestAnimationFrame(() => {
                  if (orbitControlsRef.current) {
                    orbitControlsRef.current.target.set(0, 0, 0)
                    orbitControlsRef.current.update()
                  }
                })

                handleSceneCreated() // Call your existing function
                setSceneReady(true) // Indicate the scene is ready
              }}
              camera={{ fov: 35 }}
              style={{
                backgroundColor: '#91c3de',
                position: 'relative',
                bottom: 10,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 0
              }}>
              <ambientLight intensity={0.5} />
              <spotLight position={[10, 10, 10]} angle={0.3} penumbra={0.2} intensity={2} castShadow />
              <directionalLight position={[-8, 12, 8]} intensity={10} castShadow shadow-bias={-0.0001} />
              <group position={[0, groupY, 0]}>
                <Center top>
                  <Suzi
                    ref={modelRef}
                    rotation={[0, 0, 0]}
                    scale={1}
                    color={color}
                    screenType={screenType}
                    screenColor={screenColor}
                    fabricColor={fabricColor}
                    bottomScreenType={bottomScreenType}
                    microphoneType={microphoneType}
                    activeSelection={activeSelection}
                  />
                </Center>
                <LightMarker position={lightPosition} />
              </group>
              <OrbitControls
                ref={orbitControlsRef}
                minPolarAngle={0}
                enableZoom={false}
                enablePan={false}
                maxPolarAngle={Math.PI / 2}
                enableDamping
                dampingFactor={0.05}
              />
              <AnimationUpdater />
            </Canvas>
          </div>
        </div>

        <div className="bottomhalfcontainer">
          <div className="rectangles-container">
            <div className="dots-more">
              <img
                src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Download.png?v=1730478482"
                width="30"
                height="30"
                onClick={takeScreenshot}
                alt="Download Screenshot"
                style={{ cursor: 'pointer' }} // Optional: add pointer cursor for better UXf
              />
            </div>

            <div className="share">
              <img
                src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Share.png?v=1730477928"
                width="30"
                height="30"
                onClick={shareProduct}
                alt="Share"
                style={{ cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: '100vw', height: '100vh', backgroundColor: '#ddeeff' }}>
        <motion.div
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            // Fill the screen, but push it down by translateY(...)
            transform: translateY,
            background: '#fff',
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            boxShadow: '0 -2px 10px rgba(0,0,0,0.2)',
            padding: '10px 10px 0 10px' // Top, right, bottom (0), left
          }}
          drag="y"
          dragElastic={0.2}
          onDrag={(evt, info) => {
            // info.delta.y => px movement
            // If user drags up => delta.y is negative => we want to DECREASE sheetPercent
            const deltaPx = info.delta.y
            const deltaPct = (deltaPx / screenHeight) * 100 // convert px => percent

            const prevVal = sheetPercent.get() // e.g. 35
            const nextVal = prevVal + deltaPct // we add because deltaPx is positive downward

            // clamp between 0..100 if you want to ensure it never leaves the screen
            // or 0..90 if your max is 90
            const minP = snapPercents[0] // e.g. 0
            const maxP = snapPercents[snapPercents.length - 1] // e.g. 90
            const clamped = Math.max(minP, Math.min(maxP, nextVal))

            sheetPercent.set(clamped)
          }}
          onDragEnd={handleDragEnd}>
          {/* Show min/mid/max content based on livePercent */}
          <div
            style={{
              width: '28px',
              height: '4px',
              backgroundColor: '#ccc',
              margin: '0px auto'
            }}></div>

          {livePercent < 20 ? (
            <div style={{}}>
              <h3>Minimal Content</h3>
              <p>Offset under 20%</p>
            </div>
          ) : livePercent < 70 ? (
            <div style={{}}>
              <div className="dice-icon">
                <img src="https://cdn.shopify.com/s/files/1/0564/1820/5796/files/241101_Randomize.png?v=1730478482" alt="Dice Icon" onClick={handleDiceClick} />
              </div>
              <div className="dots-container">
                <div className="control-dots">
                  <div className="dots active" onClick={() => setActiveDot('dots', resetCamera)}></div>
                  <div className="dots-side" onClick={() => setActiveDot('dots-side', resetCameraSide)}></div>
                  <div className="dots-back" onClick={() => setActiveDot('dots-back', rotateCameraBehind)}></div>
                  <div className="dots-crazy" onClick={() => setActiveDot('dots-crazy', rotateCameraBehind)}></div>
                  {/* ... other dots if needed */}
                </div>
              </div>

              <div className="scrollable-footer" ref={scrollRef}>
                {Object.keys(selectionContent).map((optionLabel) => (
                  <div
                    key={optionLabel}
                    className={`footer-option ${activeSelection === optionLabel ? 'active' : ''}`}
                    onClick={() => handleOptionClick(optionLabel)}>
                    <h1>{displayNames[optionLabel] || optionLabel}</h1>
                  </div>
                ))}
              </div>

              <div className="selection" ref={selectionRef}>
                {selectionContent[activeSelection] || <div>Select an option</div>}
              </div>

              <div className="add-to-cart" onClick={addToCart}>
                <h2>Add to Cart</h2>
              </div>
            </div>
          ) : (
            <div style={{}}>
              <div className="dots-container">
                <div className="control-dots">
                  <div className="dots active" onClick={() => setActiveDot('dots', resetCamera)}></div>
                  <div className="dots-side" onClick={() => setActiveDot('dots-side', resetCameraSide)}></div>
                  <div className="dots-back" onClick={() => setActiveDot('dots-back', rotateCameraBehind)}></div>
                  <div className="dots-crazy" onClick={() => setActiveDot('dots-crazy', rotateCameraBehind)}></div>
                </div>
              </div>
              <div className="add-to-cart-min-footer">
                <div className="add-to-cart" onClick={addToCart}>
                  <h2>Add to Cart</h2>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </div>
    </>
  )
}
